<template>

<GrowerNotVerificated 
      v-if="useAuth().isAuth() && !useAuth().isVerifiedEmail()"/>

  <div :class="{'cfrm': true}">

 
    <UiTextArea 
      :text="text"
      :rows="3"
      :placeholder="$t('comments_view_placeholder')"
      @change:text="text = $event"
      :focus-mounted="props.replyId"
      />



    <div v-if="useAuth().isAuth()">  

      <UiButton          
        @click="send"
        type="primary"
        :disabled="!useAuth().isVerifiedEmail()"
        :is-loading="isLoadingSave"
        :name="$t('universal_button_send')"                  
        />

      <UiButton
        v-if="type == 'comment' || editedId"
        @click="close"
        :name="$t('universal_button_cancel')"                  
        />
        
    </div>
    <div v-else>  
      <UiButton 
        v-if="!useAuth().isAuth()"
        @click="useAuth().goToLogin()"
        type="primary">
        <i class="icon-lock ic"></i>  {{ $t('main_menu_login') }}
      </UiButton>
    </div>
      
  </div>

</template>


<script setup>

const { $api, $popup } = useNuxtApp();
const emits = defineEmits(['closed', 'created', 'updated']);


const props = defineProps({
  type: {
    type: String
  },
  text: {
    type: String,
    default: ''
  },
  content: {
    type: Number
  }, 
  editedId: {
    type: Number
  },  
  parentId: {
    type: Number
  },
  replyId: {
    type: Number
  },
  replyToUserName: {
    type: String
  },
})

var text = ref(props.text)
var isLoadingSave = ref(false);

watch(() => props.text, (val) => {
  text.value = val;
})

const close = () => {
  emits('closed')
}

const send = () => {
  if(!text.value){
    $popup.error('Empty comment');
    return false;
  }

  isLoadingSave.value = true;
  if(props.editedId){
    $api.pathComment(props.editedId, props.type, props.content, {                    
      text: text.value,
      parent_id: props.parentId,
      reply_id: props.replyId
    }) 
    .then((res) => {      
      isLoadingSave.value = false;      
      emits('updated', text.value);                
      $popup.success('Saved');
      text.value = '';
      close();
    })
    .catch(function (error) {        
      isLoadingSave.value = false;         
      $popup.error(error);    
    });  
  }else{
    $api.postComment(props.type, props.content, {
      text: text.value,
      parent_id: props.parentId,
      reply_id: props.replyId
    }) 
    .then((res) => {           
      isLoadingSave.value = false;      
      text.value = '';            
      emits('created', res.data.value.data);    
      $popup.success('Saved');   
      // init();  
    })
    .catch(function (error) {       
      isLoadingSave.value = false;      
      $popup.error(error);               
    });  
  }

}

onMounted(() => {
  if(props.replyToUserName){
    text.value = '@' + props.replyToUserName + ', ';
  }
})

 
</script>
   

<style scoped>
   
.cfrm{
  background-color: var(--gd-comment-form-back-color);
  padding: 1.6rem;
  border-radius: 5px;
  position: relative;
}


</style>
